* {
    padding: 0;
    margin:0;
    font-family: 'Poppins',sans-serif;
}


.footer3{

    background-color: black;
    width:100%;
    height:80vh;
    display: flex;
    align-items: right;
    flex-direction: column;
    justify-content: center;

}

.footer2{
    background-color: black;
    width:100%;
    height:80vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.follow{
    display: flex;
}

.follow a{
    width: 80px;
    height: 80px;
    text-align: center;
    text-decoration: none;
    color: orange;
    box-shadow: 0 0 20px 10px rgba(0,0,0,0.05);
    margin: 0 8px;
    border-radius: 40%;
    position: relative;
    overflow: hidden;
}

.follow a .fab{
    font-size: 22px;
    line-height: 80px;
    position: relative;
    z-index: 10;
    transition: 0.5s;
}

.follow a::after{
    content: '';
    width: 100%;
    height: 100%;
    top:-90px;
    right:-90px;
    /* background: linear-gradient(319deg, #ba2d0b 0%, #fe7f2d 37%, #ffbf46 100%); */
    background: orange;
    position: absolute;
    transition: 0.5s;
}

.follow a:hover::after{
    right:0;
    top:0;
}

.follow a:hover .fab{
    color:black;
}

.copyright{
    margin-top: 2.5%;
    margin-bottom:27px;
    color:orange;
}



.heading{
    color:orange;
    font-size: 1.2rem;
    float:right;
    margin-right:48%;
    margin-top:8px;
    font-style:'Poppins',sans-serif;
    font-weight: bold;
}
.link a{
   color:orange;
    float:right;
    text-decoration:none;
   margin-right:50%;
   margin-top:20px;
   margin-left:30px;
   font-style:'Poppins',sans-serif;

}
.link a:hover{
    color:white;
}



.link_2 a{
    color:orange;
    float:right;
    text-decoration:none;
   margin-right:50%;
   padding-top:0px;
   font-style:'Poppins',sans-serif;
   

}
.link_2 a:hover{
    color:white;
}


hr{
    height:5px;
    width:90%;
    color:darkorange;
  border:1px solid;
}