.Domains{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 2rem;
    background-color: black;
    min-height: 100vh;
    align-items: center; 
    justify-content: center; 
    padding: 0 2rem;
}

.Domains-header{
display: flex;
gap: 1rem;
font-weight: bold;
font-size: 7vh;
justify-content: center;
color:#e89657 ;
text-transform: uppercase;
font-style: italic;
background: radial-gradient(93.18% 50% at 50% 50%, #F6842A 22.09%, #AF7AF2 100%);
background-clip: text;
-webkit-background-clip: text; 
color: transparent; 

}

@media only screen and (max-width: 450px) {
    .Domains-header{
        font-size: 5vh;
    }
  }
@media only screen and (max-width: 309px) {
    .Domains-header{
        font-size: 4vh;
    }
  }
@media only screen and (max-width: 260px) {
    .Domains-header{
        font-size: 3vh;
    }
  }

.Domains-categories{
    display: flex;
    /* flex-direction: column; */
    gap: 1rem;
    flex-direction: row;
    flex-wrap: nowrap;
    
}

@media only screen and (max-width: 600px) {
    .Domains-categories{
        display: flex;
        gap: 1rem;
        flex-direction: column;
        flex-wrap: wrap;
        
    }
  }


  @media only screen and (max-width: 1300px) {
    .Domains-categories{
        display: flex;
        gap: 1rem;
        flex-direction: column;
        flex-wrap: wrap;
    }
    .responsive-image{
        max-width: 380px;
    }


    
  }

.text{
    text-align: center;
    color : #F6842A;
    white-space: pre-line;
    display: block;
    line-height: 1.5;
    font-weight: 400;
    font-size: medium;

}

@media only screen and (max-width: 200px){
    .text{
        font-size: small;
    }
}

.category{
    display: flex;
    flex-direction: column;
    background: grey;
    padding: 2rem;
    gap: 1rem;
    color: white;
    justify-content: space-between;
   
}
.responsive-image {
    display: flex;
    flex: inherit;
    max-width: 180px;
    max-height: 20x;
    background-size: cover; 
    background-position: center; 
    width: fit-content;
    height: auto;
    overflow: hidden;
  }
  @media only screen and (max-width: 1300px) {
    .responsive-image{
        max-width: 300px;
        max-height: 30x;
    }
  }
@media only screen and (max-width: 400px) {
    .responsive-image{
        max-width: 150px;
        max-height: 10x;
    }
  }
@media only screen and (max-width: 250px) {
    .responsive-image{
        max-width: 100px;
        max-height: 10x;
    }
  }

.category>:nth-child(1){
    fill: white;
    font-weight: bold;
    font-size: 1rem;
    color:whitesmoke ;
    text-decoration: underline;
    font-weight: bold;
    font-style: italic;

}



.category>:nth-child(2){
    font-size: 0.8rem;
    line-height: 25px;
    font-weight: 500;
}


.category{
    
    background: linear-gradient(147.75deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.05) 100%);
    border-radius: 10px;
    left: 0;
    bottom: 0;
    /* overflow: hidden; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-size: 14px;
    transition: transform 0.5s;
}






.category:hover {
    transform: scale(1);
    
}

.layer{
    color: azure;
    text-align: justify;
}

    
.Textdata{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
@media only screen and (max-width: 1430px){
    .Textdata{
        font-size: x-large ;
    }
}


.overlay{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.765),rgb(254, 106, 0,1));
    overflow: hidden;
    width: 100;
    height: 0;
    transition: .5s ease-in-out;
    
}
.category:hover .overlay {
    height: 100%;   
}

.overlayText{
    position: absolute;
    bottom: 20%;
    left: 0;
    font-size: x-large;    
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-transform: capitalize;
    
}