body{
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    margin-top: 0px;
    background-color: black;
}

a{
    color: white;
    text-decoration: none;
}


.back-part{
    position:absolute;
    height: 1000px;
    width: auto;
    border: 1px solid red;
}


.nav{
    color: white;
    position: relative;
    display: flex;
    height: 120px;
    /* border: 2px solid white; */
    width: auto;
    gap: 10px;
    font-family: 'Abril Fatface', cursive;
}


#nav-logo{
    height:3rem;
}
#logo-div{
    flex-grow: 2;
    justify-content: baseline;
}

.nav-sub{
    display: flex;
    /* border: 1px solid white; */
    position: relative;
    height: 100px;;
    width: 120px;
    align-items: center;
    font-size: 1.2rem;
  
}

.main{
    color: white;
    position: relative;
    display: flex;
    flex-direction: row;
    height: 600px;
    /* border: 2px solid white; */
    width: auto;
    align-items: center;
}



.main_content-image{
    display: flex;
    align-content: center;
    justify-content: center;
    height: 500px;
    width:800px;
    /* border: 2px solid white; */
    
}


.main_content-text{
    position: relative;
    display: flex;
    height: 500px;
    width:800px;
    /* border: 2px solid yellow; */
    flex-direction: column;
    align-content: center;
    justify-content: center;
    gap: 20px;
}



.main-content-sub{
    position: relative;
    display: flex;
    flex-wrap:initial;
    align-items: center;
    justify-content: center;
    /* border: 1px solid white; */
    font-family: 'Abhaya Libre', serif;
    font-family: 'Abril Fatface', cursive;
    height: 120px;
    top:200px;
}


#main-content-sub-heading{
    font-size: 3.2rem;
    word-spacing: 10px;
    opacity: 20;
}

#main-content-sub-description{
    font-size: 1.4rem;
    word-spacing: 5px;
    font-family: 'Abhaya Libre', serif;
    font-family: 'Abril Fatface', cursive;
    font-family: 'Montserrat', sans-serif;
}



#fox{
    position: relative;
    top: -50px;;
    width: 120%;
    height: 120%;
    object-fit: contain;
}